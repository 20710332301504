<template>
  <div class="set-content">
    <div class="o-title">添加店招图片</div>
    <img v-if="dzInfo" class="img" :src="dzInfo" style="width: 100%;" />
    <el-upload class="upload-demo" :action="uploadUrl" :headers="headersToken" :on-success="handleCoverSuccess" :before-upload="(file) => beforeCoverUpload(file, '店招')" :show-file-list="false" :data="{ type: 1 }" name="image" accept="image/jpg, image/jpeg, image/png">
      <el-button class="button-w-b" size="large" style="width: 100%" icon="el-icon-plus">添加图片</el-button>
    </el-upload>
    <div class="o-title" style="margin-top: 20px">添加店铺LOGO</div>
    <img v-if="logo" class="img" :src="logo" />
    <el-upload class="upload-demo" :action="uploadUrl" :headers="headersToken" :on-success="handleCoverSuccess" :before-upload="(file) => beforeCoverUpload(file, 'logo')" :show-file-list="false" :data="{ type: 3 }" name="image" accept="image/jpg, image/jpeg, image/png">
      <el-button class="button-w-b" size="large" style="width: 100%" icon="el-icon-plus">添加图片</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "ShopSign",
  components: {},
  props: {
    dzInfo: {
      type: String,
    },
    logo: {
      type: String,
    },
  },
  data() {
    return {
      headersToken: {
        Authorization: localStorage.getItem("studentToken"),
      },
      uploadUrl: localStorage.getItem("hosturl") + "stuOp/dzPosterUploadImg",
      currentType: "",
    };
  },
  computed: {},
  methods: {
    beforeCoverUpload(file, type) {
      this.currentType = type;
      const isLt2M = type === "店招" ? file.size / 1024 / 1024 < 2 : file.size / 1024 < 200;
      if (!isLt2M) {
        this.$message.error(type === "店招" ? "上传图片大小不能超过2MB！" : "上传图片大小不能超过200K！");
        return false;
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = type === "店招" ? 1920 : 100;
          const height = type === "店招" ? 120 : 100;
          const _URL = window.URL || window.webkitURL;
          const img = new Image();
          img.onload = () => {
            const valid = img.width === width && img.height === height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(
          () => {
            return file;
          },
          () => {
            this.$message.warning(type === "店招" ? "图片尺寸限制为1920px*120px，大小不可超过2MB！" : "图片尺寸限制为100px*100px，大小不可超过200K！");
            return Promise.reject();
          }
        );
        return isSize;
      }
    },
    handleCoverSuccess(res, file) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.currentType === "店招" ? this.$emit("uploadShopSign", res.data) : this.$emit("uploadLogo", res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
}
.upload-demo {
  :deep(.el-upload) {
    width: 100%;
  }
}
.img {
  margin-bottom: 20px;
}
</style>
