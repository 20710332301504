<template>
  <div class="decorate-container">
    <main class="decorate-content">
      <div class="decorate-main">
        <div class="main-box">
          <div class="float-tab">用户界面设计</div>
          <div class="float-download">
            <!-- <div class="title">规则/素材下载</div> -->
            <div class="title">素材下载</div>
            <i class="iconfont" :class="{ current: editIndex === null }" @click="toggleEditCurrent(null)">&#xe709;</i>
          </div>
          <el-scrollbar class="main-content">
            <div class="shop-sign" :class="{ floatBorder: editIndex === 1 }" @click="toggleEditCurrent(1)">
              <div class="box">
                <template v-if="selectionData.dz_score">
                  <div style="position: absolute; z-index: 999; width: 760px">
                    <img :src="detailInfo.logo ? detailInfo.logo : noImg" style="width: 40px; height: 40px" />
                  </div>
                  <img :src="detailInfo.dz_info ? detailInfo.dz_info : noImg" />
                </template>
                <img :src="noSignLogImg" v-else />
              </div>
            </div>
            <div class="class-box" :class="{ floatBorder: editIndex === 2 }" @click="toggleEditCurrent(2)">
              <div class="box">
                <div class="item text-overflow">Home Page</div>
                <div class="add-btn" v-if="detailInfo.class_info.length === 0">点击此处在右侧添加分类</div>
                <template v-else>
                  <div class="item" v-for="(item, index) in detailInfo.class_info" :key="`class_info_${index}`">
                    <div class="class-levelOne text-overflow" :title="item.store_class_name">
                      {{ item.store_class_name }}
                      <el-icon v-if="item.children.length > 0">
                        <el-icon-caret-bottom />
                      </el-icon>
                    </div>
                    <div class="class-levelTwo" v-if="item.children.length > 0">
                      <div class="item-two text-overflow" :title="classTwoItem.store_class_name" v-for="(classTwoItem, classTwoIndex) in item.children" :key="`classTwo_${classTwoIndex}`">
                        {{ classTwoItem.store_class_name }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="swiper-box" :class="{ floatBorder: editIndex === 3 }" @click="toggleEditCurrent(3)">
              <el-carousel class="carousel-box" v-if="detailInfo.poster_info.length === 0">
                <el-carousel-item>
                  <img :src="noImg" alt="" class="item" />
                </el-carousel-item>
              </el-carousel>
              <el-carousel class="carousel-box" v-else>
                <el-carousel-item v-for="(item, index) in detailInfo.poster_info" :key="`poster_info_${index}`">
                  <img :src="item" alt="" class="item" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="sort-box" :class="{ floatBorder: editIndex === 4 }" @click="toggleEditCurrent(4)">
              <div class="box">
                <template v-if="detailInfo.sort_info.length === 0">
                  <div class="sort-item" v-for="(item, index) in 4" :key="`sort_info_0_${index}`">
                    <span></span>
                    <i class="iconfont">&#xe600;</i>
                  </div>
                </template>
                <template v-else>
                  <div class="sort-item" v-for="(item, index) in detailInfo.sort_info" :key="`sort_info_${index}`">
                    <span>{{ item }}</span>
                    <i class="iconfont">&#xe600;</i>
                  </div>
                </template>
              </div>
            </div>
            <div class="good-list">
              <div class="box" :class="{ floatBorder1: editIndex === 5 }" @click="toggleEditCurrent(5)">
                <template v-if="detailInfo.goods_info.length === 0">
                  <div class="good-item" style="margin-bottom: 0" v-for="(item, index) in 4" :key="`goods_info_0_${index}`">
                    <div class="good-image">
                      <div class="img-box">
                        <img src="../../../../assets/images/no-data.png" alt="" />
                      </div>
                    </div>
                    <!--                                    <div class="good-price">&yen;9.99</div>-->
                    <!--                                    <div class="good-name text-overflow-2">这是商品名称</div>-->
                  </div>
                </template>
                <template v-else>
                  <div class="good-item" v-for="(item, index) in detailInfo.goods_info" :key="`goods_info_${index}`">
                    <div class="good-image">
                      <div class="img-box">
                        <img :src="item.image_url" alt="" @click.stop="lookGoods(item.id)" />
                      </div>
                    </div>
                    <div class="good-price">￥{{ item.goods_price }}</div>
                    <div class="good-name text-overflow-2">{{ item.goods_name }}</div>
                  </div>
                </template>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <el-scrollbar class="set-container">
        <template v-if="(editIndex !== 1 && editIndex !== 3) || (editIndex === 3 && selectionData.poster_score) || (editIndex === 1 && selectionData.dz_score)">
          <div class="set-title">
            <div>{{ editIndex === null ? "考试要求" : editIndex === 1 ? "店铺设置" : editIndex === 2 ? "分类设置" : editIndex === 3 ? "Banner设置" : editIndex === 4 ? "选择排序" : "商品发布" }}</div>
            <div class="w-button" @click="toView"><i class="iconfont">&#xe61e;</i>预览</div>
          </div>
          <Download v-if="editIndex === null" :material="examData?.file_download" />
          <ShopSign :dzInfo="detailInfo.dz_info" :logo="detailInfo.logo" @uploadShopSign="uploadShopSign" @uploadLogo="uploadLogo" v-if="editIndex === 1 && selectionData.dz_score" />
          <Sort :classInfo="detailInfo.class_info" @getClassList="getClassList" v-if="editIndex === 2" />
          <Carousel :posterInfo="detailInfo.poster_info" @uploadCarousel="uploadCarousel" v-if="editIndex === 3 && selectionData.poster_score" />
          <SortReally :sortInfo="detailInfo.sort_info" @chooseSort="chooseSort" v-if="editIndex === 4" />
          <Good :goodsInfo="detailInfo.goods_info" @chooseGood="chooseGood" @getStuGoods="getStuGoods" v-if="editIndex === 5 && (selectionData.goods_img_score || selectionData.goods_details_score || selectionData.goods_short_video_score)" />
          <div class="prompt-text" v-if="editIndex === 1 || editIndex === 3">
            <div>提示：</div>
            <div style="margin: 4px 0">
              {{ editIndex === 1 ? "店招" : "Banner" }}上传尺寸为<span class="red">{{ editIndex === 1 ? "1920px*120px" : "1920px*630px" }}</span
              >，仅支持jpg、jpeg、png 格式,每张图片小于等于2M
            </div>
            <div v-if="editIndex === 1">店铺LOGO上传尺寸为<span class="red">100px*100px</span>，仅支持jpg、jpeg、png 格式,每张图片小于等于200K</div>
          </div>
          <div class="set-bottom" v-if="editIndex !== null">
            <div class="w-button blue h40" @click="submitBtn(editIndex)">确定</div>
          </div>
        </template>
      </el-scrollbar>
    </main>
  </div>
</template>

<script>
import Download from "@/components/student/dataSelection/Download.vue";
import ShopSign from "@/components/student/dataSelection/ShopSign.vue";
import Sort from "@/components/student/dataSelection/Sort.vue";
import Carousel from "@/components/student/dataSelection/Carousel.vue";
import SortReally from "@/components/student/dataSelection/SortReally.vue";
import Good from "@/components/student/dataSelection/Good.vue";
import { competitionstudent, examInfo, designModule, stuDecorateStoreClassList, stuDecorateStoreClassSave, stuDecorateDzPosterList, stuDecorateSaveDzAndPoster, stuDecorateGoodsSortList, stuDecorateStuGoodsList, stuDecorateGoodsAdd } from "@/utils/newDecorate";
import noImg from "../../../../assets/images/no-data.png";
import noBannerImg from "../../../../assets/images/no-banner.png";
import noSignLogImg from "../../../../assets/images/no-sign-logo.png";

export default {
  name: "UserPageDesign",
  components: {
    Download,
    ShopSign,
    Sort,
    Carousel,
    SortReally,
    Good,
  },
  data() {
    return {
      examData: {},
      selectionData: {},
      editIndex: null, // null：考试要求；1：店招设置；2：分类设置；3：轮播图设置；4：选择排序；5：商品设置
      detailInfo: {
        dz_info: "",
        logo: "",
        poster_info: [],
        sort_info: [],
        class_info: [],
        goods_info: [],
      },
      noImg: noImg,
      noBannerImg: noBannerImg,
      noSignLogImg: noSignLogImg,
      userId: localStorage.getItem("studentId"),
      competitionId: localStorage.getItem("examId"),
      competitionType: localStorage.getItem("exam_type"),
    };
  },
  computed: {},
  methods: {
    // getCompetitionStudent() {
    //   competitionstudent({ student_id: this.userId }).then(
    //     (res) => {},
    //     (err) => {
    //       this.$message({
    //         type: "error",
    //         message: err.message,
    //         duration: 1500,
    //         onClose: () => {
    //           this.$router.push("/");
    //           localStorage.clear();
    //         },
    //       });
    //     }
    //   );
    // },
    lookGoods(id) {
      let url = this.$router.resolve({ path: "/newDecorator/detail", query: { id } });
      window.open(url.href);
    },
    // 切换当前编辑的模块
    toggleEditCurrent(index) {
      this.editIndex = index;
      if (index == 1 && !this.selectionData.dz_score) return this.$message.warning("本轮考试无需上传店招和logo");
      if (index == 3 && !this.selectionData.poster_score) return this.$message.warning("本轮考试无需上传轮播图");
      if (index == 5 && !this.selectionData.goods_img_score && !this.selectionData.goods_details_score && !this.selectionData.goods_short_video_score) return this.$message.warning("本轮考试无需上传商品");
    },
    // 获取分类列表
    getClassList() {
      try {
        stuDecorateStoreClassList().then(({ data, msg }) => {
          this.detailInfo.class_info = data.list.map((item) => {
            item.open = true;
            return item;
          });
        });
      } catch (e) {
        this.$message.warning(e.message);
      }
    },

    // 更新店招数据
    uploadShopSign(data) {
      this.detailInfo.dz_info = data;
    },
    // 更新店招数据
    uploadLogo(data) {
      this.detailInfo.logo = data;
    },
    // 获取店招、海报列表
    getDzInfo() {
      try {
        stuDecorateDzPosterList({ type: 1 }).then(({ data, msg }) => {
          this.detailInfo.dz_info = data.url?.length ? data.url[0] : void 0;
        });
        stuDecorateDzPosterList({ type: 2 }).then(({ data, msg }) => {
          this.detailInfo.poster_info = data.url?.length ? data.url : [];
        });
        stuDecorateDzPosterList({ type: 3 }).then(({ data, msg }) => {
          this.detailInfo.logo = data.url?.length ? data.url[0] : void 0;
        });
      } catch (e) {
        this.$message.warning(e.message);
      }
    },

    // 更新轮播列表
    uploadCarousel(data) {
      this.detailInfo.poster_info.push(data);
    },

    // 更新排序
    chooseSort(data) {
      this.detailInfo.sort_info = data;
    },
    getSort() {
      try {
        stuDecorateGoodsSortList().then(({ data, msg }) => {
          this.detailInfo.sort_info = data.filter((item) => item.is_answer).map((item1) => item1.name);
        });
      } catch (e) {
        this.$message.warning(e.message);
      }
    },

    // 选择商品
    chooseGood(data) {
      let index = this.detailInfo.goods_info.findIndex((item) => {
        return item.id === data.id;
      });
      if (index > -1) {
        this.detailInfo.goods_info.splice(index, 1);
      } else {
        this.detailInfo.goods_info.push(data);
      }
    },
    getStuGoods() {
      try {
        stuDecorateStuGoodsList({ type: 2 }).then(({ data, msg }) => {
          this.detailInfo.goods_info = data.list;
        });
      } catch (e) {
        this.$message.warning(e.message);
      }
    },

    submitBtn(index) {
      if (index === 1) {
        if (!this.detailInfo.dz_info && !this.detailInfo.logo) {
          return this.$message.warning("请先上传店招和logo！");
        }
        try {
          [1, 3].map((type) => {
            let params = {
              type: type,
              url: [],
            };
            type === 1 ? params.url.push(this.detailInfo.dz_info) : params.url.push(this.detailInfo.logo);
            if (params.url.length) {
              stuDecorateSaveDzAndPoster(params).then(({ data, msg }) => {
                this.getDzInfo();
              });
            }
          });
          this.$message.success("保存成功！");
        } catch (e) {
          this.$message.warning(e.message);
        }
      }
      if (index === 2) {
        if (this.detailInfo.class_info.length === 0) {
          return this.$message.warning("请先添加分类");
        } else {
          for (let i = 0; i < this.detailInfo.class_info.length; i++) {
            if (this.detailInfo.class_info[i].store_class_name === "") {
              return this.$message.warning("请输入分类名称");
            }
            if (this.detailInfo.class_info[i].children.length > 0) {
              for (let k = 0; k < this.detailInfo.class_info[i].children.length; k++) {
                if (this.detailInfo.class_info[i].children[k].store_class_name === "") {
                  return this.$message.warning("请输入子分类名称");
                }
              }
            }
          }
        }
        try {
          stuDecorateStoreClassSave({ store_class_arr: this.detailInfo.class_info }).then(({ data, msg }) => {
            this.$message.success(msg);
            this.getClassList();
          });
        } catch (e) {
          this.$message.warning(e.message);
        }
      }
      if (index === 3) {
        if (this.detailInfo.poster_info.length === 0) {
          return this.$message.warning("请先上传轮播图");
        }
        try {
          let params = {
            type: 2,
            url: this.detailInfo.poster_info,
          };
          stuDecorateSaveDzAndPoster(params).then(({ data, msg }) => {
            this.$message.success(msg);
            this.getDzInfo();
          });
        } catch (e) {
          this.$message.warning(e.message);
        }
      }
      if (index === 5) {
        if (this.detailInfo.goods_info.length === 0) {
          return this.$message.warning("请先选择商品");
        }
        try {
          let arr = this.detailInfo.goods_info.map((item) => item.id);
          stuDecorateGoodsAdd({ ids: arr }).then(({ data, msg }) => {
            this.$message.success(msg);
            this.getStuGoods();
          });
        } catch (e) {
          this.$message.warning(e.message);
        }
      }
    },
    toView() {
      let url = this.$router.resolve({
        path: "/newDecorator/fullScreen",
      });
      window.open(url.href, "_blank");
    },
    getDesign() {
      designModule().then(({ data, msg }) => {
        this.selectionData = { ...this.selectionData, ...data };
      });
    },
    getExamInfo() {
      examInfo({ type: "store_visual_design" }).then(({ data, msg }) => {
        this.examData = data;
      });
    },
  },
  mounted() {
    // this.getCompetitionStudent();
    this.getExamInfo();
    this.getDesign();
    this.getClassList();
    this.getDzInfo();
    this.getSort();
    this.getStuGoods();
  },
};
</script>

<style scoped lang="scss">
.w-button {
  height: 30px;
  padding: 0 12px;
  color: #696970;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  .iconfont {
    margin-right: 5px;
    font-size: 16px;
  }
  &:hover {
    color: #1cb064;
  }
  &.blue {
    color: #fff;
    background: #1cb064;
    &:hover {
      background: #22be6e;
    }
  }
  & + .w-button {
    margin-left: 20px;
  }
  &.h40 {
    height: 40px;
    font-size: 14px;
  }
}

.decorate-container {
  width: 100%;
  height: 100%;
  background: #eef0f4;
}
.decorate-content {
  display: flex;
  height: calc(100vh - 50px);
  .decorate-main {
    // width: 1%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 96px 140px 56px 140px;
  }
  .main-box {
    background: #fff;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    .float-tab {
      position: absolute;
      left: 0;
      top: -60px;
      color: #696970;
      background: #e3e5e9;
      line-height: 30px;
      padding: 0 20px;
      border-radius: 4px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -30px;
      height: 30px;
      border-left: 1px dashed #bbbbbb;
    }
    .float-download {
      position: absolute;
      top: -58px;
      right: -110px;
      &:before {
        content: "";
        position: absolute;
        left: 65px;
        top: 30px;
        height: 26px;
        border-left: 1px dashed #bbbbbb;
      }
      .title {
        color: #696970;
        background: #e3e5e9;
        line-height: 30px;
        padding: 0 20px;
        border-radius: 4px;
      }
      .iconfont {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        display: block;
        line-height: 40px;
        text-align: center;
        color: #b0b0ba;
        font-size: 18px;
        margin-top: 26px;
        margin-left: 45px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover,
        &.current {
          color: #1cb064;
        }
      }
    }
  }
  .set-container {
    width: 370px;
    background: #f8f9fa;
    .set-title {
      color: #696970;
      line-height: 50px;
      border-bottom: 1px solid #e3e5e9;
      margin: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .prompt-text {
      color: #999;
      font-size: 12px;
      margin: 12px 10px 0;
    }
    .red {
      color: #ff4747;
    }
    .set-bottom {
      margin: 60px 10px 20px;
    }
  }
}

.main-content {
  min-width: 760px;
  height: 100%;
  .shop-sign {
    width: 100%;
    height: 0;
    padding-bottom: 6.25%;
    position: relative;
    .box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fafafa;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .class-box {
    width: 100%;
    background: #fff;
    .box {
      max-width: 760px;
      height: 30px;
      margin: 0 auto;
      line-height: 30px;
      color: #666;
      font-size: 12px;
      display: flex;
    }
    .add-btn {
      color: #999;
      margin-left: 50px;
      cursor: pointer;
    }
    .item {
      position: relative;
      cursor: pointer;
      transition: all 0.3s;
      max-width: 100px;
      & + .item {
        margin-left: 20px;
      }
      .class-levelOne {
        .el-icon {
          margin-left: 4px;
        }
      }
      .class-levelTwo {
        position: absolute;
        z-index: 9999;
        background: #fff;
        top: 25px;
        left: 0;
        border: 1px solid #e3e5e9;
        min-width: 60px;
        max-width: 100px;
        display: none;
      }
      &:hover {
        .class-levelTwo {
          display: block;
        }
      }
    }
  }
  ::v-deep .el-scrollbar__wrap {
    height: calc(100% + 17px);
  }
}

.swiper-box {
  width: 100%;
  height: 0;
  padding-bottom: 32.8125%;
  background: #fafafa;
  position: relative;
  .carousel-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    :deep(.el-carousel__container) {
      height: 100%;
    }
    :deep(.el-carousel__item) {
      display: flex;
      align-items: center;
      justify-content: center;
      .item {
        max-width: 100%;
        max-height: 100%;
      }
    }
    :deep(.el-carousel__button) {
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
    }
    :deep(.is-active .el-carousel__button) {
      background: #1cb064;
    }
  }
}
.sort-box {
  .box {
    max-width: 760px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 14px 0;
  }
  .sort-item {
    display: flex;
    font-size: 12px;
    border: 1px solid #e3e5e9;
    border-radius: 2px;
    line-height: 20px;
    padding: 0 10px;
    span {
      min-width: 22px;
    }
    .iconfont {
      font-size: 12px;
      transform: scale(0.5);
      margin-left: 6px;
    }
    & + .sort-item {
      margin-left: 7px;
    }
    &:first-child {
      color: #1cb064;
      background: #fafafa;
    }
  }
}
.good-list {
  margin-bottom: 40px;
  .box {
    max-width: 760px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .good-item {
    width: calc(25% - 15px);
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    /*padding: 10px;*/
    &:nth-of-type(4n + 1) {
      margin-left: 0;
    }
    .good-image {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      .img-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .good-price {
      color: #cc0000;
      margin-top: 6px;
      line-height: 1;
    }
    .good-name {
      color: #000;
      margin-top: 6px;
      line-height: 18px;
      height: 36px;
    }
  }
}

.floatBorder {
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid #1cb064;
    z-index: 999;
  }
}
.floatBorder1 {
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 10px solid #fff;
    z-index: 999;
    outline: 4px solid #1cb064;
  }
}
</style>
