<template>
  <div class="set-content">
    <div class="o-title">选择排序</div>
    <el-button class="button-w-b" size="large" style="width: 100%" @click="showFloat = !showFloat" icon="el-icon-plus">添加排序</el-button>
    <div class="sort-list" v-if="showFloat">
      <el-checkbox-group v-model="sortInfo" text-color="green" fill="red" class="checkboxGroup" @change="handleSort">
        <el-checkbox :label="item.name" v-for="(item, index) in sortList" :key="`sortList_${index}`" />
      </el-checkbox-group>
      <div class="sort-bottom">
        <el-button @click="cancelSort">取消</el-button>
        <el-button type="primary" @click="confirmBtn">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { stuDecorateSaveGoodsSort, stuDecorateGoodsSortList } from "@/utils/newDecorate";

export default {
  name: "SortReally",
  components: {},
  props: {
    sortInfo: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      checkLit: [],
      sortList: [
        {
          id: 1,
          name: "销量",
          is_answer: 0,
        },
        {
          id: 2,
          name: "价格",
          is_answer: 0,
        },
        {
          id: 3,
          name: "好评度",
          is_answer: 0,
        },
        {
          id: 4,
          name: "收藏量",
          is_answer: 0,
        },
        {
          id: 5,
          name: "转化率",
          is_answer: 0,
        },
        {
          id: 6,
          name: "回购率",
          is_answer: 0,
        },
        {
          id: 7,
          name: "浏览量",
          is_answer: 0,
        },
      ],
      showFloat: false,
    };
  },
  computed: {},
  methods: {
    handleSort(val) {
      this.$emit("chooseSort", val.sort());
    },
    cancelSort() {
      this.showFloat = false;
      this.$emit("chooseSort", this.checkLit.sort());
    },
    confirmBtn() {
      if (this.sortInfo.length === 0) {
        return this.$message.warning("请选择排序");
      }
      try {
        const result = this.sortList.filter((item) => this.sortInfo.includes(item.name));
        const arr = result.map((item) => item.id);
        stuDecorateSaveGoodsSort({ ids: arr }).then(({ data, msg }) => {
          this.$message.success(msg);
          this.showFloat = false;
        });
      } catch (e) {
        this.$message.warning(e);
      }
    },
  },
  mounted() {
    if (this.sortInfo.length) {
      this.showFloat = true;
      this.checkLit = JSON.parse(JSON.stringify(this.sortInfo));
    }
  },
};
</script>

<style scoped lang="scss">
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
  position: relative;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
}
.checkboxGroup {
  margin-top: 10px;
  :deep(.el-checkbox) {
    display: flex;
    margin-right: 0;
    font-weight: normal;
    padding: 0 10px;
  }
}
.sort-list {
  position: absolute;
  background: #fff;
  border: 1px solid #dde6f3;
  left: 10px;
  right: 10px;
  top: 111px;
  .sort-bottom {
    padding: 10px;
    border-top: 1px solid #dde6f3;
    text-align: right;
    margin-top: 10px;
  }
}
</style>
