<template>
  <div>
    <el-form-item label="商品视频" :prop="videoSrc" style="margin-bottom: 10px" class="top10"></el-form-item>
    <div class="upload-box">
      <div v-if="videoSrc">
        <div class="mp4-preview-wrap">
          <video :src="videoSrc"></video>
          <div class="video-btns">
            <div class="video-btn preview" @click="playVideo">
              <i class="el-icon-view"></i>
            </div>
            <div class="video-btn delete" @click="deleteConfirm">
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="upload-video-box" v-else @click="uploadEvt">
        <div class="upload-video-components">
          <el-icon :size="20" color="#C4C4C4">
            <i class="el-icon-plus"></i>
          </el-icon>
          <p>添加商品视频</p>
        </div>
        <input class="upload-instance" type="file" accept="video/*" ref="fileInstance" @change="uploadFile($event)" />
        <div class="process-bg" :style="{ width: speed + '%' }"></div>
        <div class="process-line" :style="{ width: process + '%' }"></div>
      </div>
    </div>
    <div class="prompt-text">视频尺寸比例为1:1或者16:9，MP4格式，视频时长≤60秒，清晰度≥720p，视频大小≤150MB。</div>
    <el-dialog v-model="dialogVisible" title="商品视频" width="800px" :before-close="handleClose">
      <div class="video-dialog">
        <video :src="videoSrc" ref="videoInstance" controls autoplay></video>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import  serviceIns  from "@/utils/request";
import { randomStr } from "@/utils/common";
import { removeGoodsVideo } from "@/utils/newDecorate";

export default {
  name: "GoodsVideo",
  components: {},
  props: {
    videoSrc: String,
    id: Number,
  },
  data() {
    return {
      fileInstance: null,
      speed: 0,
      total: 0,
      chunkIndex: 0,
      process: 0,
      dialogVisible: false,
      videoInstance: null,
    };
  },
  computed: {},
  methods: {
    playVideo() {
      this.dialogVisible = true;
      this.videoInstance.play();
    },
    deleteConfirm() {
      this.$confirm
        .confirm("确定删除商品视频？删除之后无法恢复")
        .then(() => this.deleteVideo())
        .catch();
    },
    deleteVideo() {
      let param = {
        src: this.videoSrc,
      };
      if (this.id) {
        param.id = this.id;
      }
      try {
        const res = removeGoodsVideo(param);
        this.message({
          showClose: true,
          type: "success",
          message: "删除成功",
        });
        this.emits("change", "");
      } catch (e) {
        console.log("error", e);
      }
    },
    handleClose() {
      this.videoInstance.pause();
      this.videoInstance.currentTime = 0;
      this.dialogVisible = false;
    },
    uploadEvt() {
      this.fileInstance.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const flag = randomStr(8);
      this.uploadFunc(file, flag);
      console.log("upload done.");
      this.fileInstance.value = null;
      this.chunkIndex = 0;
      this.total = 0;
    },
    uploadFunc(file, flag) {
      const chunkSize = 1024 * 1024;
      this.total = Math.ceil(file.size / chunkSize);
      let blob = file.slice(this.chunkIndex * chunkSize, (this.chunkIndex + 1) * chunkSize);
      const formData = new FormData();
      formData.append("file", blob, file.name);
      formData.append("chunkIndex", this.chunkIndex);
      formData.append("total", this.total);
      formData.append("flag", flag);
      formData.append("name", file.name);
      const config = {
        onUploadProgress(progreesEvent) {
          this.speed = Math.round((progreesEvent.loaded * 100) / progreesEvent.total);
        },
      };
      const res = serviceIns.serviceIns("decorate/video", formData, config);
      console.log("res", res.data);
      const result = res.data;
      if (result.code === 200 && result.data.status) {
        this.chunkIndex++;
        this.process = Math.round((this.chunkIndex / this.total) * 100);
        if (this.chunkIndex < this.total) {
          this.uploadFunc(file, flag);
        }
      }
      if (result.code === 200 && result.data.url) {
        this.process = 0;
        this.speed = 0;
        this.emits("change", result.data.url);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.prompt-text {
  font-size: 12px;
  color: #aeb2b9;
  margin-top: 20px;
  padding-left: 30px;
}
.upload-box {
  padding-left: 30px;
  display: flex;
  .upload-video-box {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .upload-instance {
      display: none;
    }
    .process-line {
      width: 10%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #0a84ff;
      border-radius: 2px;
      z-index: 99;
    }
    .process-bg {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 2px;
      background-color: #a1cbf7;
    }
    .upload-video-components {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin-top: 10px;
        color: #c4c4c4;
      }
    }
  }
  .mp4-preview-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    video {
      width: 100%;
      height: 100%;
    }
    background-color: rgba(0, 0, 0, 0.1);
    .video-btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.8);
      .video-btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        font-size: 12px;
        &.preview {
          color: #0a84ff;
        }
        &.delete {
          color: #ee2020;
        }
      }
    }
  }
}
.video-dialog {
  width: 100%;
  height: 740px;
  background-color: rgba(0, 0, 0, 0.5);
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
